import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ReactComponent as PlusIcon } from '@icons/wolfkit-light/plus-light.svg';
import Button from '@shared/ui/buttons/Button';
const ButtonStyled = styled(Button)(props => ({
    width: 422,
    height: 300,
    maxHeight: 'unset',
    border: `2px dashed ${props.theme.palette.primary.main}`,
}));
const AddPortfolioButton = ({ onClick = undefined, }) => {
    const { t } = useTranslation();
    return (_jsx(ButtonStyled, { variant: 'tinted', onClick: onClick, startIcon: PlusIcon, children: t('overview.portfolios_list.create_portfolio') }));
};
export default AddPortfolioButton;
