import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ContainerRow } from '@src/components/styled';
import IconText from '@shared/ui/icons/IconText';
import Icon from '@shared/ui/icons/Icon';
import { ReactComponent as InfoIcon } from '@icons/wolfkit-solid/info-circle-solid.svg';
import { ProfileSwitcher } from '@features/profile/profile-switch';
import { BodySemiBold } from '@src/components/styled/Typography';
const SwitchProfileContainer = styled(ContainerRow)(props => ({
    borderRadius: 12,
    height: 'auto',
    justifyContent: 'space-between',
    padding: `${props.theme.spacing_sizes.xs * 2.5}px ${props.theme.spacing_sizes.xs * 4}px`,
    backgroundColor: props.theme.palette.primary.light,
    boxSizing: 'border-box',
    borderBottom: `1px solid ${props.theme.customColors.shades.primaryMidLight}`,
    marginBottom: props.theme.spacing_sizes.l,
}));
const SwitchContainer = styled(ContainerRow)(props => ({
    width: 'auto',
    gap: props.theme.spacing_sizes.m,
    alignItems: 'center',
}));
const SwitchText = styled(BodySemiBold)(props => ({
    color: props.theme.palette.primary.main,
    fontSize: 14,
}));
const IconTextStyled = styled(IconText)(props => ({
    gap: props.theme.spacing_sizes.m,
    '& .MuiTypography-root': {
        color: props.theme.palette.text.primary,
        fontWeight: 500,
    },
}));
const SwitchProfileBanner = ({ description, profileType, }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const text = (profileType === 'CLIENT' &&
        t('settings.switch_to_trader', { ns: 'common' })) ||
        (profileType === 'TRADER' &&
            t('settings.switch_to_client', { ns: 'common' })) || '';
    return (_jsxs(SwitchProfileContainer, { children: [_jsx(IconTextStyled, { IconComponent: (_jsx(Icon, { IconComponent: InfoIcon, size: 16, color: theme.palette.primary.main })), text: description }), _jsxs(SwitchContainer, { children: [_jsx(SwitchText, { children: text }), _jsx(ProfileSwitcher, {})] })] }));
};
export default SwitchProfileBanner;
