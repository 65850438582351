import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import EmptyViewInteractive from '@src/components/empty-views/EmptyViewInteractive';
import Icon from '@shared/ui/icons/Icon';
import { SurfaceColumnWithShadow } from '@src/components/styled';
import { ReactComponent as ExchangesEmptyIcon } from '@icons/custom/exchanges-empty.svg';
const EmptyViewContainer = styled(SurfaceColumnWithShadow)(() => ({
    alignItems: 'center',
    justifyContent: 'center',
    padding: '60px 0',
}));
const ConnectExchangeEmptyView = ({ onConnectExchangeClick, }) => {
    const { t } = useTranslation();
    return (_jsx(EmptyViewContainer, { children: _jsx(EmptyViewInteractive, { messageTitle: t('overview.empty_states.connect_exchange.title'), messageBody: t('overview.empty_states.connect_exchange.content'), actionButtonTitle: t('overview.empty_states.connect_exchange.action_title'), BodyImgComponent: (_jsx(Icon, { size: 200, IconComponent: ExchangesEmptyIcon, keepOriginalColor: true })), onActionButtonClick: onConnectExchangeClick }) }));
};
export default ConnectExchangeEmptyView;
